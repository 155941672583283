<template>
  <Page>
    <template v-slot:content>
      <DropFilters @onFiltersChanged="onFiltersChanged" />
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page="table.options.page"
          :server-items-length="result.count"
          @update:page="load"
          @update:sort-by="load"
          @update:sort-desc="load"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
          <template v-slot:item.status="{ item }">
            <StatusChip :status="getStatus(item)" />
          </template>
          <template v-slot:item.name="{ item }">
            <a :href="`/products/list?drop.id=${item.id}`">
              {{ item.name }}
            </a>
            <template v-if="getStatus(item) === 'soon' && getMarketplacePreviewDropUrl(item)">
              &nbsp;
              (<a
                :href="getMarketplacePreviewDropUrl(item)"
                target="_blank"
              >preview</a>
              <v-icon
                title="copier"
                class="icon-copy"
                color="blue lighten-1"
                small
                @click="copyToClipBoard(getMarketplacePreviewDropUrl(item))"
              >
                mdi-content-copy
              </v-icon>)
            </template>
          </template>
          <template v-slot:item.slug="{ item }">
            {{ item.slug }}
            <a
              :href="getMarketplaceDropUrl(item)"
              target="_blank"
            >
              <v-icon
                small
                color="blue lighten-1"
              >mdi-open-in-new</v-icon>
            </a>
          </template>
          <template v-slot:item.public="{ item }">
            <span
              :class="item.public ? 'green--text' : 'red--text'"
            >
              {{ item.public ? '✓' : '✕' }}
            </span>
          </template>
          <template v-slot:item.published="{ item }">
            <span
              :class="item.published ? 'green--text' : 'red--text'"
            >
              {{ item.published ? '✓' : '✕' }}
            </span>
          </template>
          <template v-slot:item.exclusive="{ item }">
            <span
              :class="item.exclusive ? 'green--text' : 'red--text'"
            >
              {{ item.exclusive ? '✓' : '✕' }}
            </span>
          </template>
          <template v-slot:item.PRE_LAUNCH_AT="{ item }">
            <span :title="item.preLaunchAt | date">
              {{ item.preLaunchAt | date }}
            </span>
          </template>
          <template v-slot:item.LAUNCH_AT="{ item }">
            <span :title="item.launchAt | date">
              {{ item.launchAt | date }}
            </span>
          </template>
          <template v-slot:item.END_AT="{ item }">
            <span :title="item.endAt | date">
              {{ item.endAt | date }}
            </span>
          </template>
        </v-data-table>
      </v-sheet>
      <Snackbar />
    </template>
  </Page>
</template>

<script>
import { getQueryAsBoolean, getQueryString } from '@/helpers/queryString'
import axios from 'axios'
import DropFilters from '@/views/Drops/Filters.vue'
import dropMixin from '@/mixins/drop'
import Page from '@/components/core/Page.vue'
import Snackbar from '@/components/core/Snackbar.vue'
import snackbarMixin from '@/mixins/snackbar'
import StatusChip from '@/components/base/Drop/StatusChip.vue'

export default {
  name: 'DropsList',
  components: { Page, StatusChip, DropFilters, Snackbar },
  mixins: [dropMixin, snackbarMixin],
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      request: null,
      filters: {
        public: getQueryAsBoolean(this.$route.query, 'public'),
        published: getQueryAsBoolean(this.$route.query, 'published'),
        exclusive: getQueryAsBoolean(this.$route.query, 'exclusive'),
        status: this.$route.query['status'],
      },
      table: {
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortBy: ['LAUNCH_AT'],
          sortDesc: [true],
          mustSort: true,
        },
        headers: [
          {
            text: this.$i18n.t('views.drops.headers.name'),
            value: 'name',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.slug'),
            value: 'slug',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.public'),
            value: 'public',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.published'),
            value: 'published',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.exclusive'),
            value: 'exclusive',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.status'),
            value: 'status',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.pre_launch_date'),
            value: 'PRE_LAUNCH_AT',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.drops.headers.launch_date'),
            value: 'LAUNCH_AT',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.drops.headers.end_date'),
            value: 'END_AT',
            sortable: true,
          },
        ],
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
    }
  },
  computed: {
    queryString: function () {
      return '/drops/list?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'DropsList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    copyToClipBoard (text) {
      navigator.clipboard.writeText(text)
    },
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'DropsList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      const GET_DROPS_GQL = `
        query drops($page: Int!, $limit: Int!, $orderBy: DropOrderByInput, $filters: DropFiltersInput) {
          drops(limit: $limit, orderBy: $orderBy, filters: $filters, allowUnpublished: true) {
            totalNumberOfPages
            totalNumberOfItems
            page(page: $page) {
              id
              slug
              name
              preLaunchAt
              launchAt
              endAt
              archived
              public
              exclusive
              published
              previewToken
            }
          }
        }
      `
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      axios.post(process.env.VUE_APP_MARKETPLACE_GRAPHQL_URL, {
        query: GET_DROPS_GQL,
        variables: {
          page: this.table.options.page,
          limit: this.table.options.itemsPerPage,
          filters: this.filters,
          orderBy: {
            sort: this.table.options.sortBy[0] ?? 'LAUNCH_AT',
            order: this.table.options.sortDesc[0] ? 'DESC' : 'ASC',
          },
        },
      })
        .then(({ data }) => {
          this.result.items = data.data.drops.page
          this.result.count = data.data.drops.totalNumberOfItems
          this.result.loading = false
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
    getMarketplaceDropUrl(drop) {
      return new URL('/shop/drops/' + drop.slug + '/', process.env.VUE_APP_MP_BASE_URL)
    },

    getMarketplacePreviewDropUrl(drop) {
      if (!drop.previewToken) {
        return
      }

      return this.getMarketplaceDropUrl(drop) + drop.previewToken + '/'
    },
  },
}
</script>

<style scoped>
.icon-copy {
  margin-left: 5px;

  &:active {
    color: grey !important;
  }
}
</style>
