const shipmentMixin = {
  methods: {
    getStatus(drop) {
      if (drop.archived) {
        return 'archived'
      }
      if (!drop.published) {
        return 'not_published'
      }
      const now = new Date()
      const preLaunchDate = drop.preLaunchAt ? new Date(drop.preLaunchAt).getTime() : null
      const launchDate = drop.launchAt ? new Date(drop.launchAt).getTime() : null
      const endDate = drop.endAt ? new Date(drop.endAt).getTime() : null

      if (endDate && endDate < now.getTime()) return 'ended'

      if (preLaunchDate && preLaunchDate < now.getTime() ) {
        if (launchDate && launchDate > now.getTime()) return 'pre_live'
        else return 'live'
      }

      if (launchDate && now.getTime() > launchDate) return 'live'

      return 'soon'
    },
  },
}

export default shipmentMixin
