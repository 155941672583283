<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <StatusSelector
            :state.sync="filters['status']"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <PublishedSelector
            :state.sync="filters['published']"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <PublicSelector
            :state.sync="filters['public']"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <ExclusiveSelector
            :state.sync="filters['exclusive']"
            dense
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { debounce } from '@/helpers/debounce'
import ExclusiveSelector from '@/components/base/Drop/Input/ExclusiveSelector.vue'
import { getQueryAsBoolean } from '@/helpers/queryString'
import PublicSelector from '@/components/base/Drop/Input/PublicSelector.vue'
import PublishedSelector from '@/components/base/Drop/Input/PublishedSelector.vue'
import StatusSelector from '@/components/base/Drop/Input/StatusSelector.vue'

export default {
  name: 'DropFilters',
  components: { ExclusiveSelector, PublishedSelector, StatusSelector, PublicSelector },
  data() {
    return {
      filters: {
        public: getQueryAsBoolean(this.$route.query, 'public'),
        published: getQueryAsBoolean(this.$route.query, 'published'),
        exclusive: getQueryAsBoolean(this.$route.query, 'exclusive'),
        status: this.$route.query['status'],
      },
    }
  },
  watch: {
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
